@import '~antd/dist/antd.less';

:root{
    --left-width-mobile: 0rem;
    --header-height: 3rem;
    --padding: 5%;
}

.Video-container{ 
    position: relative;
    overflow: hidden;
}

.videoTag-desktop {
    visibility: visible;
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: -1;
    transform:scale(1.2);
    -ms-transform:scale(1.2); /* IE 9 */
    -moz-transform:scale(1.2); /* Firefox */
    -webkit-transform:scale(1.2); /* Safari and Chrome */
    -o-transform:scale(1.2); /* Opera */
    filter: blur(2px);
    max-width: 100%;
    min-width: 100%;
}

  
.intro{
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.intro-title{
    position: relative;
    margin-top: 20%;
    margin-left: auto;
    margin-right: auto;
    color: white;
    font-size: 3rem;
    background: #3b744b;
}

.intro-links{
    position: absolute;
    right: 4vh;
    top: 96vh;
    color: rgba(255, 255, 255, 0.25);
}

.Main-text{
    background-color: white;
    padding: 2rem;
}

.Background{
    background-color: transparent;
    width: 100vw;
    opacity: 0.9;
    z-index: -1;
    position: absolute;
}

.Header-menu{
    justify-content: center;
    height: var(--header-height);
}

.Header-menu-element{
    margin: auto
}

#Header-menu-element-logo{
    margin: 0;   
}

#Header-menu-element-settings{
    margin-right: 0;   
}

#Header-menu-element-item1{
    margin-right: 0;   
}

#Header-menu-element-item2{
    margin-left: 3%;   
    margin-right: 3%;   
}

#Header-menu-element-item3{
    margin-left: 0;
    margin-right: 0;   
}

.Header-dp{
    display: flex;
    flex-direction: row;
    color: black
}

.Header-dp-logo{
    width: 45px;
    height: 45px;
}

.Header-dp-text{
    visibility: visible;
    position: relative;
}

.Body{
    display: flex;
    flex-direction: row;
}


@media screen and (max-width: 1220px){
    .videoTag-desktop {
        transform:scale(1.5);
        -ms-transform:scale(1.5); /* IE 9 */
        -moz-transform:scale(1.5); /* Firefox */
        -webkit-transform:scale(1.5); /* Safari and Chrome */
        -o-transform:scale(1.5); /* Opera */
    }
}
@media screen and (max-width: 1000px){
    .videoTag-desktop {
        transform:scale(2);
        -ms-transform:scale(2); /* IE 9 */
        -moz-transform:scale(2); /* Firefox */
        -webkit-transform:scale(2); /* Safari and Chrome */
        -o-transform:scale(2); /* Opera */
    }
}

@media screen and (max-width: 900px){
    .videoTag-desktop {
        transform:scale(2.5);
        -ms-transform:scale(2.5); /* IE 9 */
        -moz-transform:scale(2.5); /* Firefox */
        -webkit-transform:scale(2.5); /* Safari and Chrome */
        -o-transform:scale(2.5); /* Opera */
    }
}

@media screen and (max-width: 800px) {

    .videoTag-desktop {
        transform:scale(3);
        -ms-transform:scale(3); /* IE 9 */
        -moz-transform:scale(3); /* Firefox */
        -webkit-transform:scale(3); /* Safari and Chrome */
        -o-transform:scale(3); /* Opera */
    }


    .Header-dp-logo{
        visibility: visible;
    }
    .Header-dp-text{
        visibility: hidden;
        position: absolute;
        width: 0;
    }

    #Header-menu-element-item1{
        margin-left: 0;
        margin-right: auto;
    }

    #Header-menu-element-item2{
        margin-left: 0;   
        content: "Carbon";
        margin-right: auto;   
    }
    #Header-menu-element-item1-text{
        content: "Carbon";

    }
    
    #Header-menu-element-item3{
        margin-left: 0; 
    }
}

@media screen and (max-width: 700px){
    .videoTag-desktop {
        transform:scale(3);
        -ms-transform:scale(3); /* IE 9 */
        -moz-transform:scale(3); /* Firefox */
        -webkit-transform:scale(3); /* Safari and Chrome */
        -o-transform:scale(3); /* Opera */
    }
}

@media screen and (max-width: 600px){
    .videoTag-desktop {
        transform:scale(4);
        -ms-transform:scale(4); /* IE 9 */
        -moz-transform:scale(4); /* Firefox */
        -webkit-transform:scale(4); /* Safari and Chrome */
        -o-transform:scale(4); /* Opera */
    }
}
@primary-color: #02734A;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: black;@text-color: black;@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);