.Settings-div{
    height: max-content;
}

.Settings-computer{
    visibility: visible;
}
.Settings-mobile{
    visibility: hidden;
}

.Language-button-flag{
    margin-top: auto;
    margin-bottom: auto;
}

.Language-buttons-container{
    display: flex;
    flex-direction: column;
}

.Language-button{
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem; 
    margin-bottom: 1rem;
    width: 13rem;
}

@media screen and (max-width: 800px) {
    .Settings-computer{
        visibility: hidden;
    }
    .Settings-mobile{
        visibility: visible;
    }
}