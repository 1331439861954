.Energy-tabs{
    visibility: visible;
    height: 100vh;
}

.Energy-body-right{
    height: calc(100vh - var(--header-height));
    width: calc(100vw);
    overflow-y: scroll;
    background-color: transparent;
}

.Energy-text{
    margin: 20px;
}

@media screen and (max-width: 800px) {
    .Energy-tabs{
        visibility: hidden;
        position: absolute;
    }
}