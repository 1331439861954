
.Ewaste-iframe-map{
    width: 80vw;
    height: 42vw;
    overflow-y: scroll;
    border: 1px solid f0f0f0;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    transition: all 1s ease;
}

.Ewaste-iframe-map:hover {
    border: 2px solid  #3b744b;
    border-radius: 5px;
    box-shadow: 10px 10px 10px rgba(211, 211, 211, 0.507);
}