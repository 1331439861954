:root{
    --left-width: 12.5rem;
}

.Ewaste-tabs{
    position: relative;
    visibility: visible;
    height: 100vh;
}

.Ewaste-body{
    position: flex-box;
    flex-direction: row;
}

.Ewaste-map-flex-container{
    position: flex;
    flex-direction: column;
}

.Ewaste-body-right{
    height: calc(100vh - var(--header-height));
    width: calc(100vw);
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: transparent;
}

.Ewaste-map-labels-container{
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
}
.Ewaste-blockRed{
    color: red
}

.Ewaste-blockGreen{
    color: green
}

.Ewaste-blockGreenYellow{
    color: greenyellow
}


.Ewaste-text{
    margin: 20px;
}

@media screen and (max-width: 800px) {
    .Ewaste-tabs{
        visibility: hidden;
        position: absolute;
    }
}