.CarbonEmissions-tabs{
    position: relative;
    visibility: visible;
    height: 100vh;
}

/* .CarbonEmissions-body-left{
    height: max-content;
} */

.CarbonEmissions-body-right{
    height: calc(100vh - var(--header-height));
    width: calc(100vw);
    overflow-y: scroll;
    overflow-x: hidden;
}

.CarbonEmissions-body{
    position: flex;
    flex-direction: row;
}

.CarbonEmissions-text{
}

@media screen and (max-width: 800px) {
    .CarbonEmissions-tabs{
        visibility: hidden;
        position: absolute;
    }

    .CarbonEmissions-body-right{
        height: calc(100vh - var(--header-height));
        width: 100vw;
        overflow-y: scroll;
    }
}